@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

.agrimarket-rates {
  font-family: "Roboto", sans-serif;
  font-size: 14px;

  ul {
    list-style: none;
    padding-left: 0;
  }

  button {
    cursor: pointer;
  }

  .container {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
  }

  .inline {
    display: flex;
    flex-wrap: wrap;
  }

  .options {
    button {
      border: 0;
      margin-right: 10px;
      padding: 5px 10px;
      background: #4a6688;
      border-radius: 5px;
      color: white;
      cursor: pointer;
    }
  }

  h1 {
    color: #5b6c7f;
    font-size: 40px;
    font-weight: normal;
  }

  .products {
    display: flex;
    flex-wrap: wrap;

    button.active {
      background: #3488ee;
      /*#0ef02f*/
    }
  }
  .compare {
    font-family: "Roboto Slab", sans-serif;
    li {
      position: relative;
      margin-top: 10px;
      border: 1px solid #b3c1cf;
      border-radius: 5px;
      padding: 10px;
    }

    label {
      width: 40%;
      line-height: 38px;
      text-align: right;
      padding-right: 10px;
    }

    .close {
      position: absolute;
      top: 5px;
      right: 0;
      margin-right: 0;
      background: none;
    }

    .inline {
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 10px;

      & > div {
        width: 85%;
      }
    }

    .select {
      width: 50%;

      &.product {
        div::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  .search {
    width: 100%;
    height: 40px;
    margin-top: 15px;
  }

  .datepicker {
    position: relative;

    & > button {
      position: relative;
      height: 40px;
      min-width: 385px;
      width: 100%;
      background: white;
      color: #5b6d80;
      border: 1px solid #b3c1cf;
      border-radius: 5px;
      font-size: 16px;
      line-height: 40px;
      text-align: left;
      padding-left: 25px;
    }

    img {
      position: absolute;
      top: 7px;
      right: 10px;
      display: inline-block;
    }

    .calendar {
      & > div:nth-child(2) {
        margin-left: 15rem;
      }
    }
  }

  .currency {
    position: relative;
    margin-left: 10px;

    /* & > div > div {
      height: 40px;
      width: 175px;
      margin-left: 10px;
    }

    & > div:nth-of-type(2) div {
      background: red;
    } */
  }

  .react-datepicker {
    position: absolute;
    z-index: 1;
  }

  .circle {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border-radius: 50%;
  }

  .product-name {
    display: inline-block;
    text-transform: capitalize;
  }

  .indicators {
    margin: 10px 0;
  }

  .options,
  .viz {
    padding: 15px;
  }

  .options {
    max-width: 290px;
  }

  .viz {
    width: calc(100% - 290px);
  }

  .navigation {
    label {
      color: #9fadba;
      font-size: 14px;
      font-weight: bold;
    }

    & > div {
      width: calc(50% - 5px);

      &:first-of-type {
        margin-right: 5px;

        button {
          text-align: right;
        }
      }
      &:last-of-type {
        margin-left: 5px;
        text-align: right;

        button {
          text-align: left;
        }
      }
    }

    img {
      margin-top: 8px;
      height: 28px;
      width: 12px;
    }

    button {
      width: 100%;
      background: #eef2f5;
      border: 0;
      border-radius: 0;
      color: #5a7087;
      padding: 10px;

      div {
        width: calc(100% - 25px);
      }
    }
  }

  .graph {
    margin-bottom: 25px;
    background-color: #eef2f5;
  }

  .chart {
    min-height: 425px;
    margin-left: -40px;
    .chart-title {
      font-size: 24px;

      text-align: center;
      padding-bottom: 25px;
    }

    .recharts-text.recharts-label {
      font-size: 16px;
    }
  }
}
